.slider {
    height: calc(100vh - 80px);
    width: 100vw;
    position: relative;
    overflow: hidden;

    .container {
        width: 500vw;
        height: 100%;
        display: flex;
        transition: all 1s ease;

        img {
            width: 100vw;
            height: 100%;
            flex-shrink: 0; // Prevent images from shrinking
            object-fit: cover;
        }
    }

    .icons {
        width: fit-content;
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100px;
        gap: 30px;
        margin: auto;

        .icon {
            width: 50px;
            height: 50px;
            border: 2px solid #ff4dc4;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin:0px;
            color:#ff4dc4;
            
        }
    }
}