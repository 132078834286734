.promotion {

    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 500px;   /* Adjust the image size as needed */
        height: auto;
    }
}

