.instruction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    
    .top {
      margin-bottom: 20px;
      
      img {
        display: block;
        max-width: 100%; // Makes sure the image is responsive
        height: auto;
        margin: 0 auto;  // Centers the image horizontally
      }
    }
  
    .bottom {
      text-align: center;  // Centers the text inside the bottom container
    flex-direction: column;
      h2 {
        display: flex;
        margin-bottom: 15px;
        margin-top: 0;
        font-size: 24px;
        color:#ff4dc4;
        font-family: cursive;
      }
  
      ul {
        list-style-type: disc; // Adds bullet points to the list
        padding-left: 0;  // Removes default padding from the ul
        margin: 0;
        
        li {
          text-align: left;  // Aligns the text of list items to the left
          font-size: 18px;
          line-height: 1.6;
          margin-bottom: 10px;
          max-width: 600px; // Ensures the list doesn't stretch too wide
          margin-left: auto;
          margin-right: auto;
          color:#ff4dc4;
          font-family: cursive;
        }
      }
    }
  }