.products{
    padding:20px 30px;
    display: flex;
    .left{
        flex:1;
        position: sticky;
        top:20px;
        align-self: flex-start;
    }

    .right{
        flex:3;
    }
}