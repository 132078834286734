.product{
    padding:20px 50px;
    display: flex;
    gap:50px;

    .left{
        flex:3;
        .images {
            width: 500px;
            height: 500px;
            overflow: hidden;
            position: relative;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }
        }
    }
    .right{
        flex:1;
        display: flex;
        flex-direction: column;
        gap:30px;

        .price{
            font-size: 30px;
            color: black;
            font-weight: 300;
        }
        .quantity{
            display: flex;
            align-items: center;
            gap:10px;

            button{
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: none;
              }
        }

        .add{
            width: 250px;
            padding: 10px;
            background-color: #2879fe;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            cursor: pointer;
            border: none;
            font-weight: 500;
          }
    }
}